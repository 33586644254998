import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Toggletip,
  ToggletipButton,
  ToggletipContent
} from '@carbon/react';

import {
  Help,
} from '@carbon/icons-react';

import JsonEditor from './editors/JsonEditor';

export default function JsonEditorComponent({
  json,
  onChange
}) {
  const [ editor, setEditor ] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    setEditor(new JsonEditor({
      doc: json,
      onChange: doc => {

        // TODO: removing {} and typing {} should trigger a change
        if (true || doc !== json) {
          onChange(doc);
        }
      },
      parent: ref.current
    }));
  }, []);

  useEffect(() => {
    if (editor && json !== editor._cm.state.doc.toString()) {
      editor.setDoc(json);
    }
  }, [ json ]);

  return <div className="code-editor">
    <div className="code-editor__title">
      <span className="code-editor__title-label">Context</span>
      <Toggletip className="code-editor__title-help" align={ 'top' }>
        <ToggletipButton label="Additional information">
          <Help />
        </ToggletipButton>
        <ToggletipContent>
          The context is an optional set of key-value pairs. When evaluating a FEEL expression the context will be used to produce the result.
          <a href="https://docs.camunda.io/docs/components/modeler/feel/what-is-feel/" target="_blank">Learn more about FEEL</a>
        </ToggletipContent>
      </Toggletip>
    </div>
    <div className="code-editor__editor" ref={ ref }></div>
  </div>;
}