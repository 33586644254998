import isDebug from './isDebug';

import {
  getCompletion,
  getFineTunedModels,
  getModels
} from './API';

export async function fetchOpenAICompletion(options) {
  const {
    apiKey,
    model,
    messages
  } = options;

  const chatCompletion = await getCompletion({
    apiKey,
    messages,
    model
  });

  isDebug() && console.log('[OpenAI] completion:', chatCompletion);

  return chatCompletion;
}

export async function fetchFineTunedModels(options) {
  const {
    apiKey
  } = options;

  return getFineTunedModels({
    apiKey
  });
}

export async function fetchModels(options) {
  const {
    apiKey
  } = options;

  return getModels({
    apiKey
  });
}