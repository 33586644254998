/**
 * Find the first occurrence of a tag in an XML string.
 *
 * @param {string} xml
 *
 * @returns {string|null}
 */
export default function findXMLTag(xml, tagName) {
  const parser = new DOMParser();

  const doc = parser.parseFromString(xml, 'text/xml');

  const parseError = doc.getElementsByTagName('parsererror');

  if (parseError.length > 0) {
    throw new Error('XML parsing error: ' + parseError[0].textContent);
  }

  const elements = doc.getElementsByTagName(tagName);

  if (!elements.length) {
    return null;
  }

  return elements[0].textContent.trim();
}