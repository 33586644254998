import isDebug from './isDebug';

export function addDynamoDBItem(item) {
  return fetch('/api/items', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] DynamoDB error:', error));
}

export function updateDynamoDBItemRating(id, rating) {
  return fetch(`/api/items/${ id }/update-rating`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rating
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] DynamoDB error:', error));
}

export function updateDynamoDBItemFeedback(id, feedback) {
  return fetch(`/api/items/${ id }/update-feedback`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      feedback
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] DynamoDB error:', error));
}

export function verifyCaptcha(token) {
  return fetch('/api/captcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] DynamoDB error:', error));
}

export function getCompletion({
  apiKey,
  messages,
  model
}) {
  return fetch('/api/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      apiKey,
      messages,
      model
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] OpenAI error:', error));
}

export function getModels({ apiKey}) {
  return fetch('/api/models', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      apiKey
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] OpenAI error:', error));
}

export function getFineTunedModels({ apiKey }) {
  return fetch('/api/models-finetuned', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      apiKey
    })
  })
    .then(response => response.json())
    .catch(error => isDebug() && console.error('[App] OpenAI error:', error));
}