import React, {
  Fragment,
  useEffect,
  useState
} from 'react';

import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderName,
  InlineNotification,
  Popover,
  PopoverContent,
  Select,
  SelectItem,
  TextArea,
  TextInput,
  Tile,
  ToastNotification
} from '@carbon/react';

import {
  Help,
  Settings
} from '@carbon/icons-react';

import { v4 as uuidv4 } from 'uuid';

import Chat from './Chat';

import {
  fetchFineTunedModels,
  fetchModels,
  fetchOpenAICompletion
} from './OpenAI';

import SYSTEM_MESSAGE from './system-message.md';

import {
  addDynamoDBItem,
  updateDynamoDBItemFeedback,
  updateDynamoDBItemRating
} from './API';

import withCaptcha from './withCaptcha';

import findXMLTag from './findXMLTag';

import isDebug from './isDebug';
import isDev from './isDev';

import {
  evaluate as evaluateFeel
} from './FeelEngine';

const DEFAULT_OPENAI_API_KEY = process.env.OPENAI_API_KEY;

const DEFAULT_MODEL = 'gpt-4o';

const INITIAL_CONTEXT_STRING = JSON.stringify({}, null, 2);

const EXPERIMENTAL_MESSAGE = 'The FEEL Copilot is an alpha offering that is under development. It may give unexpected or incorrect responses. By using this tool, you agree to Camunda\'s use of the anonymized input and output data and anonymized feedback to improve it.';

const DEFAULT_NOTIFICATION_TIMEOUT = 5000;

export const ERROR_CODES = {
  FETCH_ERROR: 1,
  INVALID_RESPONSE_ERROR: 2,
  INVALID_EXPRESSION_ERROR: 3
};

export default function App() {

  /* OpenAI settings */
  const [ apiKey, setApiKey ] = useState('');
  const [ model, setModel ] = useState('');
  const [ models, setModels ] = useState([]);

  const [ conversationId, setConversationId ] = useState(uuidv4());

  const [ systemMessage, setSystemMessage ] = useState(SYSTEM_MESSAGE);

  const [ contextString, setContextString ] = useState(INITIAL_CONTEXT_STRING);
  const [ context, setContext ] = useState(JSON.parse(INITIAL_CONTEXT_STRING));
  const [ items, setItems ] = useState([]);

  const [ notifications, setNotifications ] = useState([]);

  const [ fetching, setFetching ] = useState(false);

  const [ settingsOpen, setSettingsOpen ] = useState(false);
  const [ aboutOpen, setAboutOpen ] = useState(false);

  const [ isWelcome, setIsWelcome ] = useState(true);

  useEffect(() => {
    window.addEventListener('keydown', (e) => {

      // close settings and about popovers on escape
      if (e.key === 'Escape') {
        setSettingsOpen(false);
        setAboutOpen(false);
      }
    });
  }, []);

  useEffect(async () => {

    // don't fetch models in production, instead use the default model
    if (!isDev()) return;

    const fineTunedModels = await fetchFineTunedModels({ apiKey: apiKey.length ? apiKey : DEFAULT_OPENAI_API_KEY });
    const models = await fetchModels({ apiKey: apiKey.length ? apiKey : DEFAULT_OPENAI_API_KEY });

    setModels([
      ...fineTunedModels,
      ...models
    ]);

    if (!model) {
      setModel([ ...fineTunedModels, ...models ].includes(DEFAULT_MODEL) ? DEFAULT_MODEL : models[0]);
    }
  }, [ apiKey ]);

  useEffect(() => {
    try {
      setContext(JSON.parse(contextString));
    } catch (error) {
      isDebug() && console.error('[App] error parsing context:', error);
    }
  }, [ contextString ]);

  const onSubmit = withCaptcha(async (prompt, _contextString) => {
    setIsWelcome(false);

    setFetching(true);

    if (_contextString) {
      setContextString(_contextString);
    }

    let item = createItem({
      context: _contextString || contextString,
      conversationId,
      model: model.length ? model : DEFAULT_MODEL,
      prompt,
      response: null
    });

    setItems([ ...items, item ]);

    let completion = null;

    let hasErrors = false;

    try {
      completion = await fetchOpenAICompletion({
        apiKey: apiKey.length ? apiKey : DEFAULT_OPENAI_API_KEY,
        messages: toOpenAIMessages([ ...items, item ]),
        model: model.length ? model : DEFAULT_MODEL
      });

      item = {
        ...item,
        response: completion
      };
    } catch (error) {
      isDebug() && console.error('[App] error fetching OpenAI completion:', error);

      hasErrors = true;

      item = {
        ...item,
        error: error.message,
        errorCode: ERROR_CODES.FETCH_ERROR
      };
    }

    setFetching(false);

    const error = validateResponse(completion) || validateExpression(completion);

    if (!hasErrors && error) {
      hasErrors = true;

      item = {
        ...item,
        error: error.message,
        errorCode: error.code
      };
    }

    if (hasErrors) {
      const notification = {
        kind: 'error',
        title: 'An error occurred. Please try again.'
      };
      
      if (error.code === ERROR_CODES.FETCH_ERROR) {
        notification.title = 'There was an issue retrieving the response. Please try again.';
      }
      
      if (error.code === ERROR_CODES.INVALID_RESPONSE_ERROR || error.code === ERROR_CODES.INVALID_EXPRESSION_ERROR) {
        notification.title = 'There was an issue with the response. Please try again.';
      }

      if (isDebug()) {
        notification.subtitle = error.message;
        notification.timeout = 30000;
      }

      setNotifications([ ...notifications, notification ]);

      isDebug() && console.error('[App] error:', error);

      setItems(items);
    } else {
      setItems([ ...items, item ]);
    }

    await addDynamoDBItem(item);

    return !hasErrors;
  });

  const onSubmitRating = async (item, rating) => {
    await updateDynamoDBItemRating(item.id, rating);

    const index = items.findIndex((i) => i.id === item.id);

    if (index === -1) {
      return;
    }

    const updatedItem = {
      ...items[ index ],
      rating
    };

    setItems([ ...items.slice(0, index), updatedItem, ...items.slice(index + 1) ]);

    const notification = {
      kind: 'success',
      title: 'Feedback submitted'
    };

    setNotifications([ ...notifications, notification ]);
  };

  const onSubmitFeedback = async (itemId, feedback) => {
    await updateDynamoDBItemFeedback(itemId, feedback);

    const index = items.findIndex((i) => i.id === itemId);

    if (index === -1) {
      return;
    }

    const updatedItem = {
      ...items[ index ],
      feedback
    };

    setItems([ ...items.slice(0, index), updatedItem, ...items.slice(index + 1) ]);

    const notification = {
      kind: 'success',
      title: 'Feedback submitted'
    };

    setNotifications([ ...notifications, notification ]);
  };

  return <Fragment>
    <Header aria-label="FEEL Copilot Playground">
      <img src="https://docs.camunda.io/img/black-C.png" style={ { height: '55%', margin: '12px 0 12px 12px' } } />
      <HeaderName href="#" prefix="">
        FEEL Copilot Playground
      </HeaderName>
      <HeaderGlobalBar>
        {
          isDev() && <Popover align={ 'bottom-right' } open={ settingsOpen } dropShadow={ true } caret={ false }>
            <HeaderGlobalAction aria-label="Settings" onClick={ () => {
              setSettingsOpen(!settingsOpen);
              setAboutOpen(false);
            } }>
              <Settings size={ 20 } />
            </HeaderGlobalAction>
            <PopoverContent>
              <Tile>
                <h3>OpenAI Settings</h3>
                <TextInput className="input-openai-settings" labelText="API Key" id="api-key" value={ apiKey } placeholder="Enter API key" onChange={ (e) => {
                  setApiKey(e.target.value);

                  setItems([]);
                } } />
                <Select
                  id="model"
                  className="input-openai-settings"
                  labelText="Model"
                  value={ model || DEFAULT_MODEL }
                  onChange={ (e) => {
                    setModel(e.target.value);

                    setItems([]);
                  } }>
                  {
                    models.map((model) => {
                      return <SelectItem key={ model } value={ model } text={ model } />;
                    })
                  }
                </Select>
                <TextArea className="input-openai-settings" labelText="System message" id="system-message" value={ systemMessage } placeholder="Enter system message" onChange={ (e) => {
                  setSystemMessage(e.target.value);

                  setItems([]);
                } } />
              </Tile>
            </PopoverContent>
          </Popover>
        }
        <Popover align={ 'bottom-right' } open={ aboutOpen } dropShadow={ true } caret={ false }>
          <HeaderGlobalAction aria-label="About" onClick={ () => {
            setAboutOpen(!aboutOpen);

            setSettingsOpen(false);
          } }>
            <Help size={ 20 } />
          </HeaderGlobalAction>
          <PopoverContent>
            <Tile className="about">
              <h3>About</h3>
              <p>
                { EXPERIMENTAL_MESSAGE }
              </p>
              <p>
                <a href="https://docs.camunda.io/docs/components/modeler/feel/what-is-feel/" target="_blank">Learn more about FEEL</a>
              </p>
            </Tile>
          </PopoverContent>
        </Popover>
      </HeaderGlobalBar>
    </Header>
    <InlineNotification
      className="notification-experimental"
      kind="info"
      title=""
      subtitle={ EXPERIMENTAL_MESSAGE }
      caption="Please report any issues on GitHub."
    />
    <Chat
      isSubmitting={ fetching }
      isWelcome={ isWelcome }
      items={ items }
      onSubmit={ onSubmit }
      context={ contextString }
      onContextChange={ (newContextString) => setContextString(newContextString) }
      onSubmitRating={ onSubmitRating }
      onSubmitFeedback={ onSubmitFeedback } />
    <footer>
      <div>
        <a href="https://camunda.com/legal/privacy/" target="_blank">Privacy Statement</a>
      </div>
      <div>
        <a href="https://camunda.com/legal/imprint/" target="_blank">Imprint</a>
      </div>
      <div>
        <a href="https://camunda.com/brand/" target="_blank">Brand</a>
      </div>
      <div>
        Camunda © { getCurrentYear() }
      </div>
    </footer>
    <div className="notifications">
      {
        notifications.map((notification, index) => {
          return <ToastNotification
            key={ index }
            kind={ notification.kind }
            onClose={ () => {} }
            onCloseButtonClick={ () => {} }
            timeout={ notification.timeout || DEFAULT_NOTIFICATION_TIMEOUT }
            title={ notification.title }
            subtitle={ notification.subtitle }
          />;
        })
      }
    </div>
  </Fragment>;
}

function createItem({
  context,
  conversationId,
  error = null,
  errorCode = null,
  feedback = null,
  model,
  prompt,
  rating = null,
  response
}) {
  return {
    context,
    conversationId,
    date: new Date().toISOString(),
    dev: isDev(),
    error,
    errorCode,
    feedback,
    id: uuidv4(),
    model,
    prompt,
    rating,
    response,
    timestamp: Date.now()
  };
}

function toOpenAIMessages(items) {
  let messages = [
    {
      role: 'system',
      content: SYSTEM_MESSAGE
    }
  ];

  for (const item of items) {
    const {
      context,
      prompt,
      response
    } = item;

    messages.push({
      content: `${ context }
${ prompt }`,
      role: 'user'
    });

    if (response) {
      messages.push({
        role: 'assistant',
        content: response
      });
    }
  }

  isDebug() && console.log(
    '[App] messages for items',
    JSON.parse(JSON.stringify(items)),
    'are',
    messages
  );

  return messages;
}

function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * Check whether the response is valid. Return error message and code if not.
 *
 * @param {string} response
 *
 * @returns {Object|null}
 */
function validateResponse(response) {
  try {
    const markdownText = findXMLTag(response, 'markdown_free_text');

    if (!markdownText) {
      return {
        message: 'Required XML tag not found: <markdown_free_text>',
        code: ERROR_CODES.INVALID_RESPONSE_ERROR
      };
    }
  } catch (error) {
    isDebug() && console.error('[App] error validating response:', error);

    return {
      message: error.message,
      code: ERROR_CODES.INVALID_RESPONSE_ERROR
    };
  }
}

/**
 * Check whether the expression is valid. Return error message and code if not.
 *
 * @param {string} response
 *
 * @returns {Object|null}
 */
function validateExpression(response) {
  const error = validateResponse(response);

  if (error) {
    return error;
  }

  const detailedSolution = findXMLTag(response, 'detailed_solution');

  if (!detailedSolution) {
    return null;
  }

  try {
    evaluateFeel('expression', detailedSolution, {});

    return null;
  } catch (error) {
    isDebug() && console.error('[App] error validating expression:', error);

    return {
      message: error.message,
      code: ERROR_CODES.INVALID_EXPRESSION_ERROR
    };
  }
}