import React, {
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Button,
  CodeSnippet,
  TextInput,
  Tile
} from '@carbon/react';

import {
  AddComment,
  MachineLearning,
  ArrowRight,
  ThumbsDown,
  ThumbsUp
} from '@carbon/icons-react';

import { useRemark } from 'react-remark';

import FeelEditorComponent from './FeelEditorComponent';
import JsonEditorComponent from './JsonEditorComponent';

import findXMLTag from './findXMLTag';

import isDebug from './isDebug';

const RATINGS = {
  GOOD: 5,
  BAD: 1
};

const EXAMPLES = [
  {
    prompt: 'What is FEEL?',
    context: '{}'
  },
  {
    prompt: 'List all items that will be out of stock soon (less than 10 items in stock).',
    context: `{
  "inventory": [
    {
      "name": "Hammer",
      "stock": 11
    },
    {
      "name": "Screwdriver",
      "stock": 2
    },
    {
      "name": "Wrench",
      "stock": 8
    }
  ]
}`
  },
  {
    prompt: 'Calculate the total working hours excluding break time.',
    context: `{
  "workingHours": "PT8H", 
  "breakTime": "PT1H"
}`
  }
];

export default function Chat(props) {
  const {
    context,
    isSubmitting,
    isWelcome,
    items,
    onContextChange,
    onSubmit,
    onSubmitFeedback,
    onSubmitRating
  } = props;

  const [ input, setInput ] = useState('');

  const [ feedback, setFeedback ] = useState('');
  const [ feedbackItemId, setFeedbackItemId ] = useState(null);
  const [ submittingFeedback, setSubmittingFeedback ] = useState(false);

  const submitPrompt = async (value, _context) => {
    if (!value.trim().length || isSubmitting) {
      return;
    }

    setInput('');

    const success = await onSubmit(value, _context || context);

    if (!success) {
      setInput(value);
    }
  };

  const onPromptKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitPrompt(input);
    }
  };

  const submitFeedback = async (value) => {
    if (!value.trim().length || submittingFeedback) {
      return;
    }

    setSubmittingFeedback(true);

    try {
      await onSubmitFeedback(feedbackItemId, value);
    } catch (error) {
      console.error('[App] error submitting feedback:', error);
    } finally {
      setFeedback('');
      setFeedbackItemId(null);
      setSubmittingFeedback(false);
    }
  };

  const onFeedbackKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitFeedback(feedback);
    } else if (event.key === 'Escape') {
      setFeedback('');
      setFeedbackItemId(null);
    }
  };

  const ref = useRef();

  useEffect(() => {

    // TODO: get rid of setTimeout
    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      ref.current.scrollTop = ref.current.scrollHeight;

      const hasScroll = ref.current && ref.current.scrollHeight > ref.current.clientHeight;

      const margin = hasScroll ? (ref.current.offsetWidth - ref.current.clientWidth) : 0;

      ref.current.style.marginLeft = `${ margin }px`;
    }, 0);
  }, [ items ]);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onFeedback = (item) => {
    setFeedback('');
    setFeedbackItemId(item.id);
  };

  const onFeedbackReset = () => {
    setFeedback('');
    setFeedbackItemId(null);
  };

  return (
    <Fragment>
      <div className="chat">
        {
          !items.length && isWelcome && <div className="chat__welcome">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M46 14H18C13.5817 14 10 17.5817 10 22V50C10 54.4183 13.5817 58 18 58H46C50.4183 58 54 54.4183 54 50V22C54 17.5817 50.4183 14 46 14Z" fill="white"/>
              <path d="M32 50.0002C29.6899 50.0021 27.4153 49.4316 25.3795 48.3397C23.3437 47.2477 21.6102 45.6684 20.334 43.7428L23.666 41.528C24.5784 42.9031 25.8168 44.0312 27.2709 44.8115C28.7251 45.5918 30.3497 46.0002 32 46.0002C33.6503 46.0002 35.2749 45.5918 36.7291 44.8115C38.1832 44.0312 39.4217 42.9031 40.334 41.528L43.666 43.7428C42.3898 45.6684 40.6563 47.2477 38.6205 48.3397C36.5847 49.4316 34.3101 50.0021 32 50.0002ZM40 28.0002C39.2089 28.0002 38.4355 28.2348 37.7777 28.6743C37.1199 29.1138 36.6072 29.7386 36.3045 30.4695C36.0017 31.2004 35.9225 32.0046 36.0769 32.7806C36.2312 33.5565 36.6122 34.2692 37.1716 34.8286C37.731 35.388 38.4437 35.769 39.2196 35.9233C39.9956 36.0777 40.7998 35.9985 41.5307 35.6957C42.2616 35.393 42.8864 34.8803 43.3259 34.2225C43.7654 33.5647 44 32.7913 44 32.0002C44.0052 31.4735 43.9053 30.951 43.7061 30.4633C43.5069 29.9757 43.2125 29.5327 42.84 29.1602C42.4675 28.7877 42.0245 28.4933 41.5368 28.2941C41.0492 28.0949 40.5267 27.995 40 28.0002ZM24 28.0002C23.2089 28.0002 22.4355 28.2348 21.7777 28.6743C21.1199 29.1138 20.6072 29.7386 20.3045 30.4695C20.0017 31.2004 19.9225 32.0046 20.0769 32.7806C20.2312 33.5565 20.6122 34.2692 21.1716 34.8286C21.731 35.388 22.4437 35.769 23.2196 35.9233C23.9956 36.0777 24.7998 35.9985 25.5307 35.6957C26.2616 35.393 26.8864 34.8803 27.3259 34.2225C27.7654 33.5647 28 32.7913 28 32.0002C28.0052 31.4735 27.9053 30.951 27.7061 30.4633C27.5069 29.9757 27.2125 29.5327 26.84 29.1602C26.4675 28.7877 26.0245 28.4933 25.5368 28.2941C25.0492 28.0949 24.5267 27.995 24 28.0002Z" fill="black"/>
              <path d="M60 32V28H56V20C55.9976 17.879 55.154 15.8456 53.6542 14.3458C52.1544 12.846 50.121 12.0024 48 12H44V4H40V12H24V4H20V12H16C13.879 12.0024 11.8456 12.846 10.3458 14.3458C8.846 15.8456 8.00238 17.879 8 20V28H4V32H8V42H4V46H8V52C8.00238 54.121 8.846 56.1544 10.3458 57.6542C11.8456 59.154 13.879 59.9976 16 60H48C50.121 59.9976 52.1544 59.154 53.6542 57.6542C55.154 56.1544 55.9976 54.121 56 52V46H60V42H56V32H60ZM52 52C51.9988 53.0605 51.577 54.0772 50.8271 54.8271C50.0772 55.577 49.0605 55.9988 48 56H16C14.9395 55.9988 13.9228 55.577 13.1729 54.8271C12.423 54.0772 12.0012 53.0605 12 52V20C12.0012 18.9395 12.423 17.9228 13.1729 17.1729C13.9228 16.423 14.9395 16.0012 16 16H48C49.0605 16.0012 50.0772 16.423 50.8271 17.1729C51.577 17.9228 51.9988 18.9395 52 20V52Z" fill="black"/>
              <path d="M52 20H12V40H52V20Z" fill="white"/>
              <path d="M24 36C26.2091 36 28 34.2091 28 32C28 29.7909 26.2091 28 24 28C21.7909 28 20 29.7909 20 32C20 34.2091 21.7909 36 24 36Z" fill="black"/>
              <path d="M40 36C42.2091 36 44 34.2091 44 32C44 29.7909 42.2091 28 40 28C37.7909 28 36 29.7909 36 32C36 34.2091 37.7909 36 40 36Z" fill="black"/>
            </svg>
            <br />
            <p>Ask me anything about FEEL (Friendly Enough Expression Language) or try one of the examples.</p>
            <div className="chat__welcome-examples">
              {
                EXAMPLES.map((example, index) => {
                  return <Tile key={ index } className="chat__welcome-example cds--tile--clickable" onClick={ () => submitPrompt(example.prompt, example.context) }>
                    { example.prompt }
                  </Tile>;
                })
              }
            </div>
          </div>
        }
        {
          items.length > 0 && <div ref={ ref } className="chat__messages">
            <div className="chat__messages-inner">
              {
                items.flatMap((item, index) => {
                  const {
                    id,
                    prompt,
                    response,
                    rating,
                    feedback: _feedback
                  } = item;

                  let messages = [
                    <div key={ `${ id }-user` } className="chat__message chat__message--user">
                      { prompt }
                    </div>
                  ];

                  if (response) {
                    messages = [
                      ...messages,
                      <div key={ `${ id }-assistant` } className="chat__message chat__message--assistant">
                        <div className="chat__message-icon">
                          <MachineLearning />
                        </div>
                        <div className="chat__message-content">
                          { getResponse(response, context) }
                          <div className="chat__message-actions">
                            <Button
                              className={ rating === RATINGS.GOOD ? 'rating rating--good rating--active' : 'rating rating--good' }
                              size="sm"
                              kind="ghost"
                              hasIconOnly
                              renderIcon={ rating === RATINGS.GOOD ? ThumbsUp : ThumbsUp }
                              iconDescription="Good"
                              onClick={ () => {
                                onSubmitRating(item, RATINGS.GOOD);
                                onFeedbackReset();
                              } } />
                            <Button
                              className={ rating === RATINGS.BAD ? 'rating rating--bad rating--active' : 'rating rating--bad' }
                              size="sm"
                              kind="ghost"
                              hasIconOnly
                              renderIcon={ rating === RATINGS.BAD ? ThumbsDown : ThumbsDown }
                              iconDescription="Bad"
                              onClick={ () => {
                                onSubmitRating(item, RATINGS.BAD);
                                onFeedbackReset();
                              } } />
                            {
                              rating && <Button
                                className={ feedbackItemId === item.id ? 'feedback feedback--active' : 'feedback' }
                                size="sm"
                                kind="ghost"
                                hasIconOnly
                                renderIcon={ AddComment }
                                iconDescription="Feedback"
                                onClick={ () => {
                                  if (feedbackItemId && feedbackItemId === item.id) {
                                    onFeedbackReset();
                                  } else {
                                    onFeedback(item);
                                  }
                                } } />
                            }
                          </div>
                          {
                            feedbackItemId === item.id && <div className="chat__message-feedback">
                              <TextInput
                                id="chat-feedback"
                                labelText="Feedback"
                                className="chat__message-feedback-text"
                                value={ feedback || _feedback || '' }
                                onChange={ (event) => setFeedback(event.target.value) }
                                onKeyDown={ onFeedbackKeyDown }
                                spellCheck={ false }
                                autoComplete="off"
                              />
                              <Button
                                className="chat__message-feedback-button"
                                renderIcon={ ArrowRight }
                                hasIconOnly
                                iconDescription="Submit"
                                disabled={ !feedback.trim().length || submittingFeedback }
                                onClick={ () => submitFeedback(feedback) }
                              />
                            </div>
                          }
                        </div>
                      </div>
                    ];
                  }

                  return messages;
                })
              }
              {
                isSubmitting && <div className="chat__message chat__message--assistant">
                  <div className="chat__message-icon chat__message-icon--pulse">
                    <MachineLearning />
                  </div>
                  <div className="chat__message-content">
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div className="chat__input">
        <div className="chat__input-inner">
          <div className="chat__input-prompt">
            <TextInput
              id="chat-input"
              labelText=""
              ref={ inputRef }
              className="chat__input-text"
              value={ input }
              onChange={ (event) => setInput(event.target.value) }
              onKeyDown={ onPromptKeyDown }
              spellCheck={ false }
              autoComplete="off"
            />
            <Button
              className="chat__input-button"
              renderIcon={ ArrowRight }
              hasIconOnly
              iconDescription="Submit"
              disabled={ !input.trim().length || isSubmitting }
              onClick={ () => submitPrompt(input) }
            />
          </div>
          <JsonEditorComponent json={ context } onChange={ onContextChange } />
        </div>
      </div>
    </Fragment>
  );
}

const Markdown = (props) => {
  const { markdown } = props;

  const [ reactContent, setMarkdownSource ] = useRemark({
    rehypeReactOptions: {
      components: {
        code: (props) => {
          const {
            className,
            children
          } = props;


          const type = className ? 'multi' : 'inline';

          return <CodeSnippet type={ type } className={ className }>{ children }</CodeSnippet>;
        },

        // li: (props) => {
        //   const {
        //     children
        //   } = props;

        //   return <ListItem>{ children }</ListItem>;
        // },
        // ol: (props) => {
        //   const {
        //     children
        //   } = props;

        //   return <OrderedList>{ children.filter(child => child.$$typeof) }</OrderedList>;
        // },
        // ul: (props) => {
        //   const {
        //     children
        //   } = props;

        //   console.log(props);

        //   return <UnorderedList>{ children.filter(child => child.$$typeof) }</UnorderedList>;
        // }
      }
    }
  });

  useEffect(() => {
    setMarkdownSource(markdown);
  }, []);

  return reactContent;
};

/**
 * Get response. In case of an error, return the original response.
 *
 * @param {string} response
 * @param {object} context
 *
 * @returns {string}
 */
function getResponse(response, context) {
  const detailedSolution = findXMLTag(response, 'detailed_solution'),
        markdownText = findXMLTag(response, 'markdown_free_text');

  if (detailedSolution && markdownText) {
    return <Fragment>
      <FeelEditorComponent
        expression={ detailedSolution }
        context={ parseContext(context) }
        contextError={ hasParseContextError(context) }
        readOnly={ true } />
      <Markdown markdown={ markdownText } />
    </Fragment>;
  } else if (markdownText) {
    return <Markdown markdown={ markdownText } />;
  }

  isDebug() && console.error('[App] No detailed solution or free text found in response; this should never happen', response);

  return response;
}

function parseContext(context) {
  try {
    return JSON.parse(context);
  } catch (error) {
    isDebug() && console.error('[App] error parsing context:', error);

    return {};
  }
}

function hasParseContextError(context) {
  try {
    JSON.parse(context);
  } catch (error) {
    isDebug() && console.error('[App] error parsing context:', error);

    return true;
  }

  return false;
}