import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Button,
  Tile
} from '@carbon/react';

import {
  Copy,
  TrashCan,
  Play
} from '@carbon/icons-react';

import { isNil } from 'min-dash';

import FeelEditor from './editors/FeelEditor';

import {
  evaluate as evaluateFeel,
  SyntaxError
} from './FeelEngine';

import isDebug from './isDebug';

export default function FeeEditorComponent({
  context = {},
  contextError,
  dialect = 'expression',
  expression,
  onExpressionChange,
  onOutputChange = () => {},
  readOnly = true,
  showOutput = true
}) {
  const [ editor, setEditor ] = useState(null);
  const [ output, setOutput ] = useState(null);
  const [ evalError, setEvalError ] = useState(null);
  const [ syntaxError, setSyntaxError ] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    setEditor(new FeelEditor({
      doc: expression,
      context,
      dialect,
      onChange: doc => {
        if (doc !== expression) {
          onExpressionChange(doc);
        }
      },
      parent: ref.current,
      readOnly
    }));
  }, []);

  useEffect(() => {
    if (editor && expression !== editor._cm.state.doc.toString()) {
      editor.setDoc(expression);
    }
  }, [ expression ]);

  useEffect(() => {
    try {
      const result = evaluateFeel(dialect, expression, context);

      setOutput(JSON.stringify(result, null, 2));

      onOutputChange(result);

      setEvalError(null);
      setSyntaxError(null);
    } catch (error) {
      isDebug() && console.error('[FeelEditorComponent] error evaluating FEEL:', error);

      setOutput(null);

      onOutputChange(null);

      if (error instanceof SyntaxError) {
        setSyntaxError(error);
      } else {
        setEvalError(error);
      }
    }
  }, [ expression, context ]);

  return <div className="code-editor">
    <div className="code-editor__editor" ref={ ref }></div>
    {
      showOutput && !syntaxError && !contextError && <Tile className="code-editor__output">
        <Play size={ 14 } />
        <code>
          <pre>
            { getOutput(output) }
          </pre>
        </code>
      </Tile>
    }
    {
      showOutput && evalError && <Tile className="code-editor__output code-editor__output-error">
        { evalError.message }
      </Tile>
    }
    {
      syntaxError && <Tile className="code-editor__error">
        { syntaxError.message }
      </Tile>
    }
    {
      contextError && <Tile className="code-editor__error">
        Context error
      </Tile>
    }
  </div>;
}

function getOutput(output) {
  if (isNil(output)) {
    return 'null';
  }

  return output;
}